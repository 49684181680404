import React from 'react';

const AccountImpactCard = () => (
  <div className="delete-account-impact-section">
    <div className="delete-account-impact-card">
      <div className="delete-account-impact-header">
        <svg className="delete-account-impact-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
          <path d="M7 7h10v2H7zm0 4h10v2H7zm0 4h10v2H7z"/>
        </svg>
        <h3>What Will Be Deleted</h3>
      </div>
      <ul className="delete-account-impact-list">
        <li className="delete-account-impact-item">
          <svg className="delete-account-bullet-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.59 17.59 19 19 17.59 13.59 12z"/>
          </svg>
          <span>Personal information (name, email, phone number, address)</span>
        </li>
        <li className="delete-account-impact-item">
          <svg className="delete-account-bullet-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.59 17.59 19 19 17.59 13.59 12z"/>
          </svg>
          <span>All booking history and reservations (active and past)</span>
        </li>
        <li className="delete-account-impact-item">
          <svg className="delete-account-bullet-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.59 17.59 19 19 17.59 13.59 12z"/>
          </svg>
          <span>Saved payment methods and transaction history</span>
        </li>
        <li className="delete-account-impact-item">
          <svg className="delete-account-bullet-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.59 17.59 19 19 17.59 13.59 12z"/>
          </svg>
          <span>Account preferences and customized settings</span>
        </li>
        <li className="delete-account-impact-item">
          <svg className="delete-account-bullet-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.59 17.59 19 19 17.59 13.59 12z"/>
          </svg>
          <span>Any loyalty points or rewards balance</span>
        </li>
      </ul>
    </div>
  </div>
);

export default AccountImpactCard;