import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import './ContactUsSection.css';
import arrowIcon from '../../../../assets/homeArrow.webp';
import rectangleImg from '../../../../assets/JoinWaiting.webp';
import ApiCaller from '../../../../api/handlers';
import SuccessPopup from '../../../../components/SuccessPopup';
import FailurePopup from '../../../../components/FailurePopup';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { use } from 'react';

const ContactUsSection = ({ onAssetsLoaded }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isTabletOrLarger = useMediaQuery({ query: '(min-width: 769px)' });
  const containerRef = useRef(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [message, setMessage] = useState('');
  const [email2, setEmail2] = useState('');

  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    description: '',
  });

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, []);

  // Update formData
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission for sendRequest API
  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, firstName, lastName, description } = formData;

    // Validate input fields
    if (!email || !firstName || !lastName) {
      setMessage('Please fill in all required fields.');
      setShowFailure(true);
      return;
    }

    ApiCaller.sendRequest(
      email,
      firstName,
      lastName,
      description,
      () => {
        setMessage('Your request was submitted successfully!');
        setShowSuccess(true);
        setFormData({ email: '', firstName: '', lastName: '', description: '' }); // Reset form
      },
      (error) => {
        console.error('Error submitting form:', error);
        setMessage('There was an error submitting your request. Please try again.');
        setShowFailure(true);
      }
    );
  };

  // Handle subscribe API
  const handleSubscribe2 = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email2)) {
      setMessage('Please enter a valid email and try again.');
      setShowFailure(true);
      return;
    }

    ApiCaller.subscribe(
      email2,
      () => {
        setMessage('Subscribed successfully!');
        setShowSuccess(true);
        setEmail2(''); // Reset email input
      },
      (error) => {
        console.error('Error subscribing:', error);
        setMessage('There was an error subscribing. Please try again.');
        setShowFailure(true);
      }
    );
  };

  useEffect(() => {
    const loadAssets = async () => {
      try {
        // Load each Icon
        const arrowIconPromise = new Promise((resolve, reject) => {
          const img = new Image();
          img.src = arrowIcon;
          img.onload = resolve;
          img.onerror = reject;
        });

        const rectangleImgPromise = new Promise((resolve, reject) => {
          const img = new Image();
          img.src = rectangleImg;
          img.onload = resolve;
          img.onerror = reject;
        });

        await Promise.all([arrowIconPromise, rectangleImgPromise]).then(() => {
          console.log('Assets Contact Us loaded successfully');
          onAssetsLoaded();
        });
      } catch (error) {
        console.error('Error loading assets:', error);
      }
    };

    loadAssets();
  }
  , []);
  

  return (
    <div className="contact-us-home" ref={containerRef}>
      <SpeedInsights />
      <div className="contact-us-home-content">
        <div className="contact-us-home-icon">
          <img src={arrowIcon} alt="Arrow Icon" style={{ width: isMobile ? '15%' : '8%' }} />
        </div>
        <div className="contact-us-home-section">
          <div className="contact-us-home-container" style={{ width: isMobile ? '80%' : isTabletOrLarger ? '40%' : '60%' }}>
            <h2 style={{ fontSize: isMobile ? '24px' : '32px' }}>Contact us</h2>
            <p style={{ fontSize: isMobile ? '14px' : '16px' }}>The harder you work for something, the greater you’ll feel when you achieve it.</p>
            
            <form className="contact-us-home-form-landing" onSubmit={handleSubmit}>
              <input
                type="email"
                name="email"
                placeholder="Email"
                required
                className="form-input-landing"
                value={formData.email}
                onChange={handleChange}
              />
              <div className="form-input-group-landing">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First name"
                  required
                  className="form-input-landing"
                  value={formData.firstName}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last name"
                  required
                  className="form-input-landing"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </div>
              <input
                type="text"
                name="description"
                placeholder="Description (optional)"
                className="form-input-landing"
                value={formData.description}
                onChange={handleChange}
              />
              <button type="submit" className="submit-btn-landing">Send request</button>
            </form>
          </div>
        </div>
      </div>
      <div className="contact-us-home-home-footer">
        <img src={rectangleImg} alt="Footer Image" />
        <div className="footer-content">
            <h3>BE THE FIRST TO KNOW</h3>
            <h3>JOIN OUR WAITING LIST</h3>
            <div className="subscribe-form">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="input-field"
                  required
                  value={email2}
                  onChange={(e) => setEmail2(e.target.value)}
                />
                <button type="button" className="subscribe-button" onClick={handleSubscribe2}>
                  Subscribe
                </button>
            </div>
        </div>
      </div>
      {showSuccess && <SuccessPopup message={message} onClose={() => setShowSuccess(false)} />}
      {showFailure && <FailurePopup message={message} onClose={() => setShowFailure(false)} />}
    </div>
  );
};

export default ContactUsSection;
