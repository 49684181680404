import { useState, useEffect } from 'react';
import ApiCaller from '../../../api/handlers';

const useDeleteAccount = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    reason: '',
    feedback: '',
    otp: '',
    authType: 'regular' // 'regular', 'google', or 'apple'
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [otpTimer, setOtpTimer] = useState(0);
  const [otpSent, setOtpSent] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failureMessage, setFailureMessage] = useState('');

  // Timer countdown effect
  useEffect(() => {
    let interval;
    if (otpTimer > 0) {
      interval = setInterval(() => {
        setOtpTimer(prev => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [otpTimer]);

  // Form validation effect
  useEffect(() => {
    const isValid = (
      formData.email && 
      (formData.authType === 'regular' 
        ? formData.password 
        : (otpSent && formData.otp)) &&
      document.getElementById('acknowledge')?.checked
    );
    setIsFormValid(isValid);
  }, [formData, otpSent]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleAuthTypeChange = (type) => {
    setFormData({
      ...formData,
      authType: type,
      password: '', // Clear password when switching to social auth
      otp: '' // Clear OTP when switching auth types
    });
    setOtpSent(false);
    setOtpTimer(0);
  };

  const handleSendOtp = async () => {
    try {
      // Call your API to send OTP
      await ApiCaller.sendOtp(formData.email);
      setOtpSent(true);
      setOtpTimer(300);
      setSuccessMessage('OTP sent successfully!');
      setShowSuccess(true);
      return true;
    } catch (error) {
      console.error('Error sending OTP:', error);
      setFailureMessage('Failed to send OTP. Please try again.');
      setShowFailure(true);
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const confirmDeletion = () => {
    setIsDeleting(true);
    
    const submissionData = {
      email: formData.email,
      reason: formData.reason,
      feedback: formData.feedback,
      authType: formData.authType
    };

    // Include password or OTP based on auth type
    if (formData.authType === 'regular') {
      submissionData.password = formData.password;
    } else {
      submissionData.otp = formData.otp;
    }

    ApiCaller.deleteAccount(
      submissionData,
      () => {
        setSuccessMessage("Your account has been successfully deleted. Redirecting to the homepage...");
        setShowSuccess(true);
        setIsDeleting(false);
        setShowConfirmation(false);
        setTimeout(() => window.location.href = '/', 3000);
      }, 
      (data) => {
        setFailureMessage(data.message || "An error occurred while processing your request. Please try again later.");
        setShowFailure(true);
        setIsDeleting(false);
        setShowConfirmation(false);
        console.log('Error deleting account:', data);
      }
    );
  };

  return {
    formData,
    showSuccess,
    showFailure,
    isDeleting,
    showConfirmation,
    isFormValid,
    otpSent,
    otpTimer,
    successMessage,
    failureMessage,
    handleChange,
    handleAuthTypeChange,
    handleSendOtp,
    handleSubmit,
    confirmDeletion,
    setShowConfirmation,
    setShowSuccess,
    setShowFailure
  };
};

export default useDeleteAccount;