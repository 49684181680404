import React from 'react';

const DeleteAccountForm = ({ 
  formData, 
  isFormValid, 
  isDeleting, 
  otpSent, 
  otpTimer, 
  handleChange, 
  handleAuthTypeChange, 
  handleSendOtp, 
  handleSubmit 
}) => {
  return (
    <form onSubmit={handleSubmit} className="delete-account-form">
      <h3>Confirm Account Deletion</h3>
      
      <div className="delete-account-form-group">
        <label htmlFor="email">Email Address</label>
        <input
          type="email"
          id="email"
          name="email"
          required
          value={formData.email}
          onChange={handleChange}
          className="delete-account-form-input"
        />
      </div>
      
      <div className="delete-account-auth-type">
        <label>How did you sign up?</label>
        <div className="delete-account-auth-options">
          <label className={`delete-account-auth-option ${formData.authType === 'regular' ? 'selected' : ''}`}>
            <input 
              type="radio" 
              name="authType" 
              checked={formData.authType === 'regular'}
              onChange={() => handleAuthTypeChange('regular')}
            />
            <div className="delete-account-auth-icon">🔒</div>
            Email & Password
          </label>
          
          <label className={`delete-account-auth-option ${formData.authType === 'google' ? 'selected' : ''}`}>
            <input 
              type="radio" 
              name="authType" 
              checked={formData.authType === 'google'}
              onChange={() => handleAuthTypeChange('google')}
            />
            <div className="delete-account-auth-icon">G</div>
            Google Account
          </label>
          
          <label className={`delete-account-auth-option ${formData.authType === 'apple' ? 'selected' : ''}`}>
            <input 
              type="radio" 
              name="authType" 
              checked={formData.authType === 'apple'}
              onChange={() => handleAuthTypeChange('apple')}
            />
            <div className="delete-account-auth-icon">🍎</div>
            Apple ID
          </label>
        </div>
      </div>
      
      {formData.authType === 'regular' ? (
        <div className="delete-account-form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            required
            value={formData.password}
            onChange={handleChange}
            className="delete-account-form-input"
          />
        </div>
      ) : (
        <>
          <div className="delete-account-form-group">
            <div style={{ display: 'flex', gap: '0.75rem' }}>
              <input
                type="text"
                id="otp"
                name="otp"
                required
                value={formData.otp}
                onChange={handleChange}
                className="delete-account-form-input"
                placeholder="Enter verification code"
                style={{ flex: 1 }}
              />
              <button
                type="button"
                className="delete-account-send-code-btn"
                onClick={handleSendOtp}
                disabled={!formData.email || otpTimer > 0}
              >
                {otpTimer > 0 ? `Resend (${Math.floor(otpTimer / 60)}:${String(otpTimer % 60).padStart(2, '0')})` : 'Send Code'}
              </button>
            </div>
            {otpTimer > 0 && (
              <div className="delete-account-timer">
                Verification code sent! Expires in {Math.floor(otpTimer / 60)}:{String(otpTimer % 60).padStart(2, '0')}
              </div>
            )}
          </div>
        </>
      )}
      
      <div className="delete-account-form-group">
        <label htmlFor="reason">Reason for leaving (optional)</label>
        <select
          id="reason"
          name="reason"
          value={formData.reason}
          onChange={handleChange}
          className="delete-account-form-input"
        >
          <option value="">Select a reason...</option>
          <option value="privacy-concerns">Privacy concerns</option>
          <option value="too-many-emails">Too many emails</option>
          <option value="found-better-service">Found a better service</option>
          <option value="technical-issues">Technical issues</option>
          <option value="other">Other</option>
        </select>
      </div>
      
      {formData.reason === 'other' && (
        <div className="delete-account-form-group">
          <label htmlFor="feedback">Additional feedback (optional)</label>
          <textarea
            id="feedback"
            name="feedback"
            placeholder="Please share any feedback to help us improve"
            value={formData.feedback}
            onChange={handleChange}
            className="delete-account-form-input"
            rows="4"
          ></textarea>
        </div>
      )}
      
      <div className="delete-account-checkbox-group">
        <input 
          type="checkbox" 
          id="acknowledge" 
          required 
          className="delete-account-custom-checkbox"
        />
        <label htmlFor="acknowledge">
          I understand that this action is irreversible and all my data will be permanently deleted.
        </label>
      </div>
      
      <button
        type="submit"
        className={`delete-account-btn ${!isFormValid ? 'disabled' : ''}`}
        disabled={!isFormValid || isDeleting}
      >
        {isDeleting ? (
          <>
            <span className="delete-account-spinner"></span>
            Processing...
          </>
        ) : (
          'Request Account Deletion'
        )}
      </button>
    </form>
  );
};

export default DeleteAccountForm;