import React from 'react';

const ConfirmationModal = ({ isDeleting, onCancel, onConfirm }) => (
  <div className="delete-account-confirmation-modal">
    <div className="delete-account-confirmation-dialog">
      <div className="delete-account-confirmation-header">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
        </svg>
        <h3>Final Confirmation</h3>
      </div>
      
      <p>Are you absolutely sure you want to permanently delete your account?</p>
      <p>This action cannot be undone.</p>
      <div className="delete-account-confirmation-actions">
        <button 
          onClick={onCancel} 
          className="delete-account-cancel-btn"
          disabled={isDeleting}
        >
          Cancel
        </button>
        <button 
          onClick={onConfirm} 
          className="delete-account-confirm-delete-btn"
          disabled={isDeleting}
        >
          {isDeleting ? (
            <>
              <span className="delete-account-spinner"></span>
              Deleting...
            </>
          ) : (
            'Yes, Delete Permanently'
          )}
        </button>
      </div>
    </div>
  </div>
);

export default ConfirmationModal;