import React from 'react';
import './Policies.css';
import policiesData from './policiesData.json'; // Adjust the path as needed

const Policies = () => {
    return (
        <div className='policies-body'>
            <div className="policy-container">
                {policiesData.map((section, index) => (
                    <div key={index} className="policy-section">
                        {/* Render Title if it exists */}
                        {section.title && <h1 className="policy-title">{section.title}</h1>}

                        {/* Render Subtitle if it exists */}
                        {section.subtitle && <h3 className="policy-subtitle">{section.subtitle}</h3>}

                        {/* Render Sub-Subtitle if it exists */}
                        {section.subSubtitle && <h4 className="policy-subsubtitle">{section.subSubtitle}</h4>}

                        {/* Render Paragraphs if they exist */}
                        {section.paragraphs && section.paragraphs.map((paragraph, pIndex) => (
                            <p key={pIndex} className="policy-paragraph">{paragraph}</p>
                        ))}

                        {/* Render Bullet Points if they exist */}
                        {section.bullets && (
                            <ul className="policy-bullet-points">
                                {section.bullets.map((bullet, bIndex) => (
                                    <li key={bIndex} className="policy-bullet-point">{bullet}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Policies;