import React , {useEffect}from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import './JoinUsAsAPartner.css';
import how21 from '../../../../assets/joinUs1.webp';
import how22 from '../../../../assets/joinUs2.webp';
import how23 from '../../../../assets/joinsUs3.webp';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { use } from "react";

const JoinUs = ({onAssetsLoaded}) => {
    const { scrollYProgress } = useScroll();

    // Transform values for image motion
    const y1 = useTransform(scrollYProgress, [0, 1], ['0%', '20%']);
    const y2 = useTransform(scrollYProgress, [0, 1], ['0%', '-20%']);
    const y3 = useTransform(scrollYProgress, [0, 1], ['0%', '15%']);

    // Motion variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3, // Maintain original stagger timing
            },
        },
    };

    const itemVariants = {
        hidden: { x: -50, opacity: 0 },
        visible: {
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                stiffness: 100,
            },
        },
    };

    useEffect(() => {
        // Function to load assets
        const loadAssets = async() => {
          try {
            // Load each Icon 
            const how21Promise = new Promise((resolve, reject) => {
                const img = new Image();
                img.src = how21;
                img.onload = resolve;
                img.onerror = reject;
            });

            const how22Promise = new Promise((resolve, reject) => {
                const img = new Image();
                img.src = how22;
                img.onload = resolve;
                img.onerror = reject;
            });

            const how23Promise = new Promise((resolve, reject) => {
                const img = new Image();
                img.src = how23;
                img.onload = resolve;
                img.onerror = reject;
            });

            await Promise.all([how21Promise, how22Promise, how23Promise]).then(() => {
                console.log('Assets What Drives Us loaded successfully');
                onAssetsLoaded();
            });

          } catch (error) {
            console.error('Error loading assets:', error);
          }
        }
    
        loadAssets();
      }
      , []);
    return (
        <div>
            <SpeedInsights />
            <section className="how-to-partner-section">
                <motion.div
                    className="step-container"
                    variants={containerVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.2 }}
                >
                    {/* Step 1 */}
                    <motion.div
                        className="step-item"
                        variants={itemVariants}
                        // whileHover={{
                        //     scale: 1.05,
                        //     transition: { type: "spring", stiffness: 300 },
                        // }}
                    >
                        <motion.img
                            className="main-image1"
                            src={how21}
                            alt="Step 1 Icon"
                            style={{ y: y1 }}
                        />
                        <div className="step-content">
                            <motion.span
                                className="step-number"
                                transition={{ type: "spring", stiffness: 300 }}
                            >
                                1
                            </motion.span>
                            <div className="step-text">
                                <h3>Get in touch</h3>
                                <p>
                                    Reach out to us via our contact form or email to
                                    express your interest in partnering with us. Provide
                                    a brief overview of your business and the type of
                                    partnership you're seeking.
                                </p>
                            </div>
                        </div>
                    </motion.div>

                    {/* Step 2 */}
                    <motion.div
                        className="step-item"
                        variants={itemVariants}
                        // whileHover={{
                        //     scale: 1.05,
                        //     transition: { type: "spring", stiffness: 300 },
                        // }}
                    >
                        <motion.img
                            className="main-image2"
                            src={how23}
                            alt="Step 2 Icon"
                            style={{ y: y2 }}
                        />
                        <div className="step-content">
                            <motion.span
                                className="step-number"
                                transition={{ type: "spring", stiffness: 300 }}
                            >
                                2
                            </motion.span>
                            <div className="step-text">
                                <h3>Discuss your needs</h3>
                                <p>
                                    Our team will set up a meeting to understand your
                                    needs and explore how our solutions can address
                                    them. We'll discuss collaboration opportunities and
                                    tailor our approach to your goals.
                                </p>
                            </div>
                        </div>
                    </motion.div>

                    {/* Step 3 */}
                    <motion.div
                        className="step-item"
                        variants={itemVariants}
                        // whileHover={{
                        //     scale: 1.05,
                        //     transition: { type: "spring", stiffness: 300 },
                        // }}
                    >
                        <motion.img
                            className="main-image3"
                            src={how22}
                            alt="Step 3 Icon"
                            style={{ y: y3 }}
                        />
                        <div className="step-content">
                            <motion.span
                                className="step-number"
                                transition={{ type: "spring", stiffness: 300 }}
                            >
                                3
                            </motion.span>
                            <div className="step-text">
                                <h3>Formalize the partnership</h3>
                                <p>
                                    Once we've aligned on objectives and solutions,
                                    we'll craft a partnership agreement outlining the
                                    scope, responsibilities, and benefits. After
                                    reviewing and signing the agreement, we'll begin
                                    working together to bring your vision to life.
                                </p>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            </section>
        </div>
    );
};

export default JoinUs;
