import React, { useState, useEffect, useCallback, Suspense, lazy } from 'react';
import './Home.css';
import { Helmet } from "react-helmet-async";
import { SpeedInsights } from "@vercel/speed-insights/react";
import LogoAnimation from './LogoAnimation';
import Landing from './Components/Landing/Landing.jsx'
// const WhatDrivesUs = import('./Components/WhatDrivesUs/WhatDrivesUs');
import WhatDrivesUs from './Components/WhatDrivesUs/WhatDrivesUs.jsx'
// const JoinUs = import('./Components/JoinUsAsAPartner/JoinUsAsAPartner');
import JoinUs from './Components/JoinUsAsAPartner/JoinUsAsAPartner.jsx'
// const ContactUsSection = import('./Components/ContactUsSection/ContactUsSection');
import ContactUsSection from './Components/ContactUsSection/ContactUsSection.jsx';
const Home = () => {
  const [showAnimation, setShowAnimation] = useState(true);
  const [isLandingAssetsLoading , setIsLandingAssetsLoading ] = useState(true)
  const [isWhatDrivesUsAssetsLoading , setIsWhatDrivesUsAssetsLoading ] = useState(true)
  const [isJoinUsAssetsLoading , setIsJoinUsAssetsLoading ] = useState(true)
  const [isContactUsAssetsLoading , setIsContactUsAssetsLoading ] = useState(true)
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (!isLandingAssetsLoading && !isWhatDrivesUsAssetsLoading && !isJoinUsAssetsLoading && !isContactUsAssetsLoading) {
      setIsVisible(false);
      setShowAnimation(false);
    }
  }, [isLandingAssetsLoading, isWhatDrivesUsAssetsLoading, isJoinUsAssetsLoading, isContactUsAssetsLoading]);


  const renderContent = () => (
    <div className="home-container">
  
      <div className="content">
          <Landing onAssetsLoaded={() => setIsLandingAssetsLoading(false)}/>
          <WhatDrivesUs onAssetsLoaded={() => setIsWhatDrivesUsAssetsLoading(false)}/>
          <JoinUs onAssetsLoaded={() => setIsJoinUsAssetsLoading(false)}/>
          <ContactUsSection onAssetsLoaded={() => setIsContactUsAssetsLoading(false)}/>
      </div>
    </div>
  );

  return (
    <div className="Home">
      <SpeedInsights />
            <Helmet>
              
        <title>Trego Tech</title>
        <meta name="description" content="Welcome to Trego Tech! Explore our services and innovative solutions for technology and growth. Our upcoming flagship product, the Trego app, offers customers a streamlined platform to book trains, buses, and flights from various transport providers. Through our innovative API integrations, we deliver real-time options, empowering users to make smarter travel decisions." />
        <meta name="keywords" content="Trego Tech, technology, innovative solutions, tech growth" />
        <meta property="og:title" content="Trego Tech" />
        <meta property="og:description" content="Welcome to Trego Tech! Explore our services and innovative solutions for technology and growth. Our upcoming flagship product, the Trego app, offers customers a streamlined platform to book trains, buses, and flights from various transport providers. Through our innovative API integrations, we deliver real-time options, empowering users to make smarter travel decisions." />
        <meta property="og:url" content="https://www.tregotech.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      {showAnimation && (
          <LogoAnimation isVisible={isVisible} onAnimationComplete={() => setShowAnimation(false)} />
       
      )}
       { renderContent() }
      
    </div>
  );
};

export default Home;