import axios from 'axios';
import ApiConsts from './consts';
const ApiCaller = {

    sendRequest: async (email, firstName, lastName, description, onSuccess , onFailure) => {
        try {
            const response = await axios.post(`${ApiConsts.BASE_URL}${ApiConsts.PARTNERS}`, {
                email,
                firstName,
                lastName,
                description,
            },
          {   headers: {
                'Content-Type': 'application/json',
              }
        }
    );
            onSuccess(response.data);

        } catch (error) {
            onFailure(error);
        }
    },

    sendContact: async (email, firstName, lastName, description, reason , onSuccess , onFailure) => {
        try {
            const response = await axios.post(`${ApiConsts.BASE_URL}${ApiConsts.CONTACTUS}`, {
                email,
                firstName,
                lastName,
                description,
                reason,
            },
            {   headers: {
                'Content-Type': 'application/json',
              }
        }
    );
            console.log('Form Data Submitted:', response.data);
            onSuccess(response.data);
        } catch (error) {
            console.error('Error submitting form:', error);
            onFailure(error);
        }

    
    },

    subscribe: async (email, onSuccess , onFailure) => {
        try {
            const response = await axios.post(`${ApiConsts.BASE_URL}${ApiConsts.WAITINGLIST}`, {
                email,
            },
            {   headers: {
                'Content-Type': 'application/json',
              }
        }
    );
            onSuccess(response.data);
        } catch (error) {
            console.error('Error submitting form:', error);
            onFailure(error);
        }

  
    },
    
    sendOtp: async (email) => {
        try {
            const response = await axios.post(`${ApiConsts.DELETEACCOUNT_BASE_URL}${ApiConsts.GENERATE_OTP}`, {
                type: 'email',
                identifier: email,
            },
            {   headers: {
                'Content-Type': 'application/json',
              }
        }
    );
            console.log('OTP Sent:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error sending OTP:', error);
            throw error;
        }
    },

    deleteAccount: async (data, onSuccess, onFailure) => {
        console.log('Data:', data);
        
        try {
            const response = await axios.delete(`${ApiConsts.DELETEACCOUNT_BASE_URL}${ApiConsts.DELETEACCOUNT}`, {
                data,
            },
            {   headers: {
                'Content-Type': 'application/json',
              }
            });
            console.log('Account Deleted:', response.data);
            onSuccess(response.data);
        } catch (error) {
            console.error('Error deleting account:', error);
            const errorMessage = error.response?.data?.message || 'An unexpected error occurred while deleting the account.';
            onFailure({ message: errorMessage });
        }
    },

};

export default ApiCaller;
