// WhatDrivesUs.js
import React  , {useEffect}from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import './WhatDrivesUs.css';
import affordabilityIcon from '../../../../assets/affordabilityIcon.webp';
import reliabilityIcon from '../../../../assets/reliabilityIcon.webp';
import customerFocusIcon from '../../../../assets/customerFocusIcon.webp';
import safetyIcon from '../../../../assets/safetyIcon.webp';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { use } from 'react';

const WhatDrivesUs = ({onAssetsLoaded}) => {
  const navigate = useNavigate();

  // Optimized scroll transform with throttle
  const { scrollYProgress } = useScroll({
    throttle: 100,
  });
  const y = useTransform(scrollYProgress, [0, 1], ['0%', '50%']);

  // Navigation handler
  const handleNavigate = () => {
    navigate('/partner');
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.1 },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 80 },
    },
  };

  const serviceVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: { type: 'spring', stiffness: 80 },
    },
    hover: {
      scale: 1.05,
      boxShadow: '0px 5px 10px rgba(0,0,0,0.2)',
    },
  };

  // Static content for values and services
  const values = [
    {
      icon: affordabilityIcon,
      title: 'Adaptable Technology',
      description:
        'Our solutions are built to scale and pivot, allowing us to explore new industries as opportunities arise.',
    },
    {
      icon: reliabilityIcon,
      title: 'Innovation-Driven',
      description:
        'We stay at the forefront of technology, constantly refining our skills to deliver efficient and impactful solutions.',
    },
    {
      icon: customerFocusIcon,
      title: 'Client-Centric',
      description:
        'We work closely with clients to understand their challenges and tailor solutions to meet their goals.',
    },
    {
      icon: safetyIcon,
      title: 'Safety',
      description: 'Your security is our top priority throughout your journey.',
    },
  ];

  const services = [
    {
      title: 'Mobile App Development',
      subtext:
        'We specialize in creating fast, reliable, and user-centric mobile and web applications. While currently focused on the travel sector, our expertise enables us to explore new industries as opportunities emerge.',
    },
    {
      title: 'API Integration & Development',
      subtext:
        'We integrate third-party services and develop custom APIs to ensure seamless business operations. While currently focused on travel provider integration, our platform is designed to adapt to other industries in the future.',
    },
    {
      title: 'Future Industry Exploration',
      subtext:
        'At Trego Tech, we are not limited to a single sector. As new opportunities arise, we stay flexible and ready to explore various industries, providing clients with tailored digital solutions wherever they\'re needed most.',
    },
  ];

  useEffect(() => {
    // Function to load assets
    const loadAssets = async() => {
      try {
        // Load each Icon 
        const affordabilityIconPromise = new Promise((resolve, reject) => {
          const img = new Image();
          img.src = affordabilityIcon;
          img.onload = resolve;
          img.onerror = reject;
        });

        const reliabilityIconPromise = new Promise((resolve, reject) => {
          const img = new Image();
          img.src = reliabilityIcon;
          img.onload = resolve;
          img.onerror = reject;
        });

        const customerFocusIconPromise = new Promise((resolve, reject) => {
          const img = new Image();
          img.src = customerFocusIcon;
          img.onload = resolve;
          img.onerror = reject;
        });

        const safetyIconPromise = new Promise((resolve, reject) => {
          const img = new Image();
          img.src = safetyIcon;
          img.onload = resolve;
          img.onerror = reject;
        });

        const images = [affordabilityIconPromise, reliabilityIconPromise, customerFocusIconPromise, safetyIconPromise];
        await Promise.all(images).then(() => {


          console.log('Assets What Drives Us loaded successfully');
          
          onAssetsLoaded();
        });
      } catch (error) {
        console.error('Error loading assets:', error);
      }
    }

    loadAssets();
  }
  , []);
  return (
    <div className="what-drives-us-section">
      <SpeedInsights />
      {/* Background image with scroll animation */}
      <motion.div className="background-image" style={{ y }} />

      {/* Main content */}
      <motion.div
        className="what-drives-us"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={containerVariants}
      >
        <motion.h2 variants={itemVariants}>WHAT DRIVES US</motion.h2>
        <motion.p variants={itemVariants}>
          Trego Tech is constantly looking ahead. We believe that our technology expertise can be applied to various
          sectors beyond travel. Our approach is built on flexibility and innovation, allowing us to develop solutions
          that meet the needs of a changing market.
        </motion.p>

        {/* Values Grid */}
        <motion.div className="values-grid" variants={containerVariants}>
          {values.map((item, index) => (
            <motion.div
              key={index}
              className="value-item"
              variants={itemVariants}
            >
              <img loading="lazy" src={item.icon} alt={item.title} />
              <div className="value-text">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>

      {/* Explore Services Section */}
      <motion.div
        className="explore-services"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={containerVariants}
      >
        <motion.h2 variants={itemVariants}>EXPLORE OUR SERVICES</motion.h2>
        <motion.p variants={itemVariants}>
          Introducing a range of solutions designed to meet your needs and elevate your business.
        </motion.p>
        <motion.div className="services-grid" variants={containerVariants}>
          {services.map((service, index) => (
            <motion.div
              key={index}
              className="service-item"
              variants={serviceVariants}
              whileHover="hover"
            >
              <span>{service.title}</span>
              <small>{service.subtext}</small>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>

      {/* Partner Section */}
      <motion.div
        className="partner-section"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.5 }}
      >
        <div className="partner-text">
          <h2>JOIN US AS A PARTNER TODAY</h2>
          <h3>AND UNLOCK NEW</h3>
          <h3>OPPORTUNITIES TOGETHER!</h3>
        </div>
        <motion.button
          className="partner-button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleNavigate}
        >
          Get in touch
        </motion.button>
      </motion.div>
    </div>
  );
};

// Memoized for performance
export default WhatDrivesUs;
