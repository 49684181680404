import React from 'react';
import './DeleteAccount.css';
import SuccessPopup from '../../components/SuccessPopup.js';
import FailurePopup from '../../components/FailurePopup.js';
import { Helmet } from 'react-helmet-async';
import { SpeedInsights } from "@vercel/speed-insights/react";
import WarningBanner from './components/WarningBanner';
import AccountImpactCard from './components/AccountImpactCard';
import AlternativeOptions from './components/AlternativeOptions';
import DeleteAccountForm from './components/DeleteAccountForm';
import useDeleteAccount from './hooks/useDeleteAccount';
import ConfirmationModal from './components/ConfirmationModal';

const DeleteAccount = () => {
  const {
    formData,
    showSuccess,
    showFailure,
    isDeleting,
    showConfirmation,
    isFormValid,
    otpSent,
    otpTimer,
    successMessage,
    failureMessage,
    handleChange,
    handleAuthTypeChange,
    handleSendOtp,
    handleSubmit,
    confirmDeletion,
    setShowConfirmation,
    setShowSuccess,
    setShowFailure
  } = useDeleteAccount();

  return (
    <div className="delete-account-container">
      <SpeedInsights />
      <Helmet>
        <title>Delete Account - Trego Tech</title>
        <meta name="description" content="Request to delete your Trego Tech account and associated data." />
        <meta name="keywords" content="Trego Tech, delete account, data removal, account closure" />
        <meta property="og:title" content="Delete Account - Trego Tech" />
        <meta property="og:description" content="Request to delete your Trego Tech account and associated data." />
        <meta property="og:url" content="https://www.tregotech.com/delete-account" />
        <meta property="og:type" content="website" />
      </Helmet>
      
      <div className="delete-account-card">
        <div className="delete-account-header">
          <div className="delete-account-warning-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/>
            </svg>
          </div>
          <h1>Delete Your Account</h1>
          <p className="delete-account-subtitle">This action is permanent and cannot be undone</p>
        </div>
        
        <WarningBanner />
        
        <div className="delete-account-deletion-details">
          <AccountImpactCard />
          <AlternativeOptions />
        </div>
        
        <DeleteAccountForm 
          formData={formData}
          isFormValid={isFormValid}
          isDeleting={isDeleting}
          otpSent={otpSent}
          otpTimer={otpTimer}
          handleChange={handleChange}
          handleAuthTypeChange={handleAuthTypeChange}
          handleSendOtp={handleSendOtp}
          handleSubmit={handleSubmit}
        />
        
        {showSuccess && (
          <SuccessPopup 
            message={successMessage} 
            onClose={() => setShowSuccess(false)} 
          />
        )}
        
        {showFailure && (
          <FailurePopup 
            message={failureMessage}
            onClose={() => setShowFailure(false)} 
          />
        )}
        
        {showConfirmation && (
          <ConfirmationModal 
            isDeleting={isDeleting}
            onCancel={() => setShowConfirmation(false)}
            onConfirm={confirmDeletion}
          />
        )}
      </div>
    </div>
  );
};

export default DeleteAccount;