import React, { useRef , useState , useEffect } from "react";
import { motion, useInView } from "framer-motion";
import { useNavigate } from "react-router-dom";
import "./Landing.css";
import image1 from "../../../../assets/landing1.webp";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { useMediaQuery } from "react-responsive";
import { SpeedInsights } from "@vercel/speed-insights/react";

// GLB Model component with rotation
function Model() {
  const { scene } = useGLTF("https://tregoimages.s3.eu-central-1.amazonaws.com/aboutus/Tlogo3.glb");
  const modelRef = useRef();

  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y += 0.0025;
    }
  });

  return <primitive object={scene} scale={0.5} ref={modelRef} />;
}

// Animation variants
const itemVariants = {
  hidden: { y: 50, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      damping: 15,
      stiffness: 100,
    },
  },
};

const AnimatedSection = ({ children, className }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  return (
    <motion.div
      ref={ref}
      className={className}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={itemVariants}
    >
      {children}
    </motion.div>
  );
};

const Landing = ({onAssetsLoaded}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const navigate = useNavigate();

  const [loadingAssets, setLoadingAssets] = useState(true);

  useEffect(() => {
    // Function to load assets
    const loadAssets = async () => {
      try {
        // Load the 3D model
        const modelPromise = new Promise((resolve) => {
          useGLTF.preload("https://tregoimages.s3.eu-central-1.amazonaws.com/aboutus/Tlogo3.glb");
          resolve();
        });

        // Load the image
        const imagePromise = new Promise((resolve) => {
          const img = new Image();
          img.src = image1;
          img.onload = resolve;
        });

        // Wait for all assets to load
        await Promise.all([modelPromise, imagePromise]);
        console.log("Assets loaded successfully");
        
        setLoadingAssets(false); // Set loading to false after all assets are loaded
        onAssetsLoaded();
      } catch (error) {
        console.error("Error loading assets:", error);
      }
    };

    loadAssets();
  }, []);

  // Navigation function
  const handleAboutClick = () => {
    navigate("/about");
  };

  return (

    <div className={`landing-container ${isTabletOrMobile ? "mobile" : ""}`}>
      <SpeedInsights />
      {/* Left Section */}
      <AnimatedSection className="section left">
        <h2>TREGO TECH DEVELOPS INNOVATIVE SOLUTIONS</h2>
        <p>
          Trego Tech is more than a travel technology company—we’re a partner in
          digital transformation. As we grow, we aim to expand our portfolio
          with solutions catering to various industries, ensuring that our
          technology continues to make an impact wherever it is needed.
        </p>
      </AnimatedSection>

      {/* Model Section */}
      <AnimatedSection className="section right model-container">
      <React.Suspense fallback={<div></div>}>
        <Canvas camera={{ fov: 3.5, position: [-100, 20, 10] }}>
          <OrbitControls enableZoom={false} enablePan={false} autoRotate={false} enableRotate={false} />
          <ambientLight intensity={0.9} />
          <directionalLight position={[2, 5, 2]} />
          <Model />
        </Canvas>
      </React.Suspense>

      </AnimatedSection>

      {/* Image Section */}
      <AnimatedSection className="section right">
        <motion.div
          whileHover={{ scale: 1.02 }}
          transition={{ type: "spring", stiffness: 300 }}
        >
          <img
            src={image1}
            alt="Software solution illustration"
            className="illustration-image"
          />
        </motion.div>
      </AnimatedSection>

      {/* Closing Section */}
      <AnimatedSection className="section right">
        <p>Driven by purpose, grounded in values.</p>
        <h2>OPEN TO NEW HORIZONS</h2>
        <p>
          Trego Tech develops innovative solutions that empower people to
          connect, discover new experiences, and grow their businesses.
        </p>
        <motion.button
          className="btn"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleAboutClick}
        >
          About us
        </motion.button>
      </AnimatedSection>
    </div>
  );
};

export default Landing;