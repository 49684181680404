
const ApiConsts = {
    BASE_URL: 'https://api.tregotech.com/tr/',
    PARTNERS: 'partners/newPartners',
    WAITINGLIST: 'waitingList/insertWaitingList',
    CONTACTUS: 'contactus/addContact',
    DELETEACCOUNT_BASE_URL: 'https://api.tregotech.com',
    DELETEACCOUNT: '/auth/deleteByEmail',
    GENERATE_OTP: '/auth/otp/generate-otp'

};

export default ApiConsts;