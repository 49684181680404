import React from 'react';
import './Footer.css';
import logo from '../assets/HeaderLogo.webp'; 
import app_logo from '../assets/logo trego.webp'; 
import facebookIcon from '../assets/Facebook.webp'; 
import linkedinIcon from '../assets/Linkedin.webp'; 
import instagramIcon from '../assets/Instagram.webp';  
import appStoreBadge from '../assets/appStore.webp'; 
import googlePlayBadge from '../assets/googlePlay.webp';
import SuccessPopup from './SuccessPopup';
import ErrorPopUp from './FailurePopup';

const Footer = () => {
  const [showSuccessPopup, setShowSuccessPopup] = React.useState(false);
  const [showErrorPopup, setShowErrorPopup] = React.useState(false);
  const handleOnButtonClick = () => {
    setShowErrorPopup(true);
    

  }

  return (
    <footer className="footer">
      <div className="footer-container">
      <div className="logo-container">
              <img src={logo} alt="Logo" />
            </div>
        <div className="footer-sections">
          <div className="footer-section learn-more">
            <ul>
              <li><a href="/contact">Contact us</a></li>
              <li>info@tregotech.com</li>
            </ul>
            <div className="social-icons">
              <a href="https://www.facebook.com/profile.php?id=61568587636160&mibextid=ZbWKwL"><img src={facebookIcon} alt="Facebook" /></a>
              <a href="https://www.linkedin.com/company/trego-tech/"><img src={linkedinIcon} alt="LinkedIn" /></a>
              <a href="https://www.instagram.com/trego_app?igsh=MXF4dWc0b29tOXh5eA=="><img src={instagramIcon} alt="Instagram" /></a>
            </div>

          </div>

          <div className="footer-section learn-more">
            <ul>
              <li><a href="/app">Trego App</a></li>
              <li><a href="#">Get the App</a></li>
              <li><a href="/app">How it works</a></li>
              <li><a href="/app">Transportation</a></li>
              {/* <li><a href="#">Accommodation</a></li> */}
              <li><a href="/partner">Become a partner</a></li>
            </ul>
          </div>

          <div className="footer-section learn-more">
            <ul>
              <li><a href="/about">Learn More</a></li>
              <li><a href="/">Home</a></li>
              <li><a href="/about">About Us</a></li>
              <li><a href="/partner">FAQ</a></li>
              <li><a href="/policies">Policies</a></li>
              <li><a href="/terms">Terms & Conditions</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </div>

          <div className="footer-section-app-download">
            <div className="app-logo-container">
              <img src={app_logo} alt="Logo" />
            </div>
            <div className="app-links">
              <a onClick={handleOnButtonClick}><img src={appStoreBadge} alt="App Store" /></a>
              <a onClick={handleOnButtonClick}><img src={googlePlayBadge} alt="Google Play" /></a>
            </div>
            <p className='copyright'>Copyright © 2024. All rights reserved.</p>
          </div>
        </div>
      </div>
      {showSuccessPopup && <SuccessPopup message="Success!" onClose={() => setShowSuccessPopup(false)} />}
      {showErrorPopup && <ErrorPopUp message="App not available yet!" onClose={() => setShowErrorPopup(false)} />}

    </footer>

  );
};

export default Footer;
