import React from 'react';

const AlternativeOptions = () => (
  <div className="delete-account-detail-section">
    <h3>Before you go</h3>
    <div className="delete-account-alternatives">
      <div className="delete-account-alternative-option">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z"/>
          <path d="M12 18c3.31 0 6-2.69 6-6s-2.69-6-6-6-6 2.69-6 6 2.69 6 6 6zm-1-6h2v-4h-2v4zm0-5h2v-2h-2v2z"/>
        </svg>
        <div>
          <h4>Having issues?</h4>
          <p>Our support team can help resolve any problems you're experiencing.</p>
          <a href="/contact" className="delete-account-alt-link">Contact Support</a>
        </div>
      </div>
      
      <div className="delete-account-alternative-option">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z"/>
          <path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"/>
        </svg>
        <div>
          <h4>Privacy concerns?</h4>
          <p>You could verify from our policies how we handle data collection</p>
          <a href="/policies" className="delete-account-alt-link">Privacy Policies</a>
        </div>
      </div>
    </div>
  </div>
);

export default AlternativeOptions;